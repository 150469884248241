<template>
	<div>
        <Dialog style="background: #720000;" :header="titulo()" v-model:visible="$store.state.notificacion" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true">
				<br>			
                <Card style="margin-bottom: 2em" v-for="(stats, index) in $store.state.notificaciones" :key="index">
					<template #content>
						<div v-html="stats" class="content ql-editor"></div>
					</template>
				</Card>
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="$store.state.notificacion=false"/>
            </template>
        </Dialog>
	</div>
</template>

<script>

export default {
    data() {
        return {
            size: '30%',
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '100%';}
        
    },
    methods: {
        titulo(){
            if (this.$store.state.notificaciones.length>1){
                return 'Notificaciones: '+this.$store.state.notificaciones.length;
            } else {
                return 'Notificación';
            }
        }
	},
    watch: {
	},


}
</script>